<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12" v-if="loadingAction">
          <Loading />
        </v-col>
        <v-col md="12" v-if="!loadingAction">
          <h1 class="systemTitle">Add Service Center</h1>
          <v-form ref="investmentForm">
            <div>
              <v-label>Vehicle Type</v-label>
              <v-autocomplete
                outlined
                placeholder="Select Vehicle Type"
                v-model="category"
                :items="categories"
                item-text="value"
                item-value="value"
                @change="selectCategory"
                multiple
              ></v-autocomplete>
            </div>
            <div>
              <v-label>Brand</v-label>
              <v-autocomplete
                outlined
                placeholder="Select Brand"
                v-model="brand"
                :items="brands"
                item-text="value"
                item-value="value"
              ></v-autocomplete>
            </div>
            <div>
              <v-label>Service Center</v-label>
              <v-text-field
                v-model="name"
                outlined
                placeholder="Charging Station Name"
                class="mt-1"
              ></v-text-field>
            </div>

            <div>
              <v-label>Province</v-label>
              <v-text-field
                v-model="province"
                outlined
                placeholder="Province"
                class="mt-1"
              ></v-text-field>
            </div>
            <div>
              <v-label>City</v-label>
              <v-text-field
                v-model="city"
                outlined
                placeholder="City"
                class="mt-1"
              ></v-text-field>
            </div>
            <div>
              <v-label>Address</v-label>
              <v-text-field
                v-model="address"
                outlined
                placeholder="Address"
                class="mt-1"
              ></v-text-field>
            </div>
            <div>
              <v-label>Phone Number</v-label>
              <v-text-field
                v-model="telephone"
                outlined
                placeholder="Phone Number"
                class="mt-1"
              ></v-text-field>
            </div>
            <div>
              <v-label>Latitude</v-label>
              <v-text-field
                v-model="latitude"
                outlined
                placeholder="Latitude"
                class="mt-1"
              ></v-text-field>
            </div>
            <div>
              <v-label>Longitude</v-label>
              <v-text-field
                v-model="longitude"
                outlined
                placeholder="Longitude"
                class="mt-1"
              ></v-text-field>
            </div>

            <v-btn @click="validate" v-if="!loading" large
              >Add Service Center</v-btn
            >
            <v-btn v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddServiceCenter",
  data: () => ({
    name: undefined,
    loading: false,
    limit: 100,
    page: 1,
    connectedTo: undefined,
    brands: [],
    categories: [],
    category: undefined,
    filters: undefined,
    brand: undefined,
    province: undefined,
    city: undefined,
    address: undefined,
    type: undefined,
    telephone: undefined,
    latitude: undefined,
    longitude: undefined,
  }),
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      "postServiceCenter",
      "getBrandList",
      "getFilters",
      "getSingleServiceCenter",
    ]),
    async selectCategory() {
      await this.getFilters({ category: this.category });
      this.filters = this.allFilters.results;
      this.brands = this.filters.brand.items;
    },
    async validate() {
      this.loading = true;
      let formData = {};
      if (this.name != undefined) {
        formData.name = this.name;
      }
      if (this.category != undefined) {
        formData.type = this.category;
      }
      if (this.city != undefined) {
        formData.city = this.city;
      }
      if (this.brand != undefined) {
        formData.brand = this.brand;
      }
      if (this.address != undefined) {
        formData.address = this.address;
      }
      if (this.province != undefined) {
        formData.province = this.province;
      }
      if (this.latitude != undefined) {
        formData.latitude = this.latitude;
      }
      if (this.longitude != undefined) {
        formData.longitude = this.longitude;
      }
      if (this.telephone != undefined) {
        formData.telephone = this.telephone;
      }

      if (this.$route.params.id != undefined) {
        formData._id = this.$route.params.id;
      }

      await this.postServiceCenter(formData);

      this.$router.push("/business/serviceCenter");

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allBrands", "allFilters", "allServiceCenter"]),
  },
  async created() {
    this.loadingAction = true;
    await this.getFilters();
    this.filters = this.allFilters.results;
    this.categories = this.filters.category.items;
    if (this.$route.params.id != undefined) {
      await this.getSingleServiceCenter(this.$route.params.id);
      this.name = this.allServiceCenter.results.name;
      this.city = this.allServiceCenter.results.city;
      this.province = this.allServiceCenter.results.province;
      this.address = this.allServiceCenter.results.address;
      this.telephone = this.allServiceCenter.results.telephone;
      this.latitude = this.allServiceCenter.results.latitude;
      this.longitude = this.allServiceCenter.results.longitude;
      this.category = this.allServiceCenter.results.type;
      await this.selectCategory();
      this.brand = this.allServiceCenter.results.brand;
    }
    this.loadingAction = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  display: flex;
  flex-flow: column;
  img {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>